import cn from 'classnames';
import React from 'react';
import * as Icons from './icons';

import * as styles from './style.module.scss';

export type IconNames = Icons.names;

export interface IconProps {
  name: IconNames;
  id?: string;
  imageIcon?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  additionalClass?: string;
  size?: 'xs' | 's' | 'm' | 'l';
}

const Icon: React.FC<IconProps> = ({ name, size, onClick, imageIcon, id, additionalClass = undefined, ...rest }) => {
  if (!Icons.icons.includes(name)) {
    // eslint-disable-next-line no-console
    console.warn(`Icon "${name}" doesn't exist!`);

    return null;
  }

  const Image = Icons[name];

  return (
    <i
      id={id}
      className={cn(
        styles.icon,
        {
          [styles.extraSmall]: size === 'xs',
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
          [styles.imageIcon]: imageIcon,
        },
        additionalClass
      )}
      {...rest}
    >
      {/* @ts-ignore */}
      <Image onClick={onClick} data-testid={`icon-${name}`} />
    </i>
  );
};

export default Icon;
