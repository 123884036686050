import styled from 'styled-components';
import tw from 'twin.macro';
import { Container } from 'components/ui/Container/styles';

export const Header = styled.header`
  ${tw`bg-gray-100 border-b border-gray-200 -mb-px z-30`};
  //background: #a2b6d3;
  //background: #c0dbff82;
  //background: #d9c183;
`;

export const Wrapper = styled(Container)`
  ${tw`items-center justify-between sm:px-20 md:px-0`};
`;

export const Text = styled.h1`
  ${tw`text-sm md:text-base lg:text-lg block sm:hidden md:block text-center -ml-16 md:ml-0`};
  max-width: 160px;
`;
