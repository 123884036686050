import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import Headroom from 'react-headroom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Newsletter from 'components/Newsletter';

import 'assets/styles/global.scss';
import GlobalStyles from 'assets/styles/globalStyles';
import { GlobalStyles as GlobalTwStyles } from 'twin.macro';
import * as Styled from './styles';
import * as styles from './Layout.module.scss';
import NotificationWrapper from '../NotificationWrapper';
import CookieConsent from 'react-cookie-consent';
import { useI18next } from 'gatsby-plugin-react-i18next';
import isBrowser from '../../utils/isBrowser';

interface Props {
  children: React.ReactNode;
  id: string;
}

const randomText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla dapibus sagittis luctus. Etiam turpis eros, rutrum ac velit eget, egestas rhoncus nulla. Pellentesque quam turpis, faucibus in libero vitae, pulvinar aliquam lacus. Aenean ut placerat felis, in malesuada lacus. Quisque laoreet diam turpis, sit amet pretium mauris pulvinar eu. Fusce finibus quam suscipit, sagittis nisl malesuada, rhoncus turpis. Vivamus ornare purus vitae varius pulvinar. Proin sed nisl a augue cursus dictum. In maximus nibh in ligula venenatis ultricies. Nunc ut erat felis. Proin eleifend suscipit lectus.

Donec a ex diam. Donec quis molestie elit, sit amet iaculis justo. Duis rutrum mi at est eleifend lacinia. Cras interdum vestibulum dictum. Pellentesque quis nunc sapien. Aliquam congue est non dolor egestas, nec lacinia ex fringilla. Mauris ullamcorper massa vel tortor semper, maximus blandit ipsum porttitor. Maecenas sit amet metus mauris. Vivamus nec est vulputate, ullamcorper libero et, vestibulum massa. Vestibulum tristique tristique vehicula. Proin venenatis dictum odio vitae tincidunt. Phasellus in aliquet urna. Etiam mollis tortor vitae ultrices condimentum. Nunc vel massa sed odio efficitur pharetra. Donec sapien elit, faucibus mollis sollicitudin sed, tincidunt et mi. Aenean at enim non libero lobortis tempus non eget ipsum.

Quisque metus nunc, fermentum at tempus vel, venenatis at nisl. Nunc sagittis neque ut egestas tincidunt. Quisque euismod malesuada accumsan. Proin ante leo, tempus et placerat sed, commodo sit amet est. Donec vehicula feugiat risus in accumsan. Vivamus aliquam, velit sed malesuada tincidunt, neque libero maximus tellus, ut vehicula nunc nunc et nibh. Integer lobortis suscipit dui, pellentesque efficitur tortor porta vel.

Maecenas ac pretium augue. Nam at sodales mi, posuere hendrerit felis. Nunc eget euismod orci, a hendrerit leo. Proin quis est cursus, consectetur ligula sit amet, tempus sapien. In eget congue nunc. Quisque eu ligula ac neque bibendum consequat. Morbi vitae ultricies lacus, vel molestie metus.

Morbi in suscipit risus. Curabitur dapibus a eros in vehicula. Nunc vel nisl elementum, lacinia urna eget, sagittis magna. Curabitur ultricies, neque sed viverra facilisis, lorem massa aliquam sem, id efficitur enim sapien id ligula. Proin sodales dapibus magna et interdum. In faucibus volutpat tellus, id convallis elit suscipit sit amet. Quisque nulla nibh, imperdiet sed tincidunt ac, placerat vitae odio. Duis ut nunc placerat metus cursus lobortis ut at nisl. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet mi non accumsan lobortis. Vivamus mattis velit ex, ac imperdiet justo pulvinar non. Nullam eget risus felis.`;

const Layout: React.FC<Props> = ({ children, id }) => {
  const { t } = useI18next();
  const cookieHref = `"${t('cookieHref')}"`;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    if (isBrowser() && window && document) {
      // window.oncopy = handler;
      // window.addEventListener('copy', handler, useCapture);
      // window.attachEvent('oncopy', handler);

      document.addEventListener('copy', function (e) {
        try {
          const text = randomText.substring(0, window.getSelection()?.toString().length || 12);

          navigator.clipboard.writeText(text)
          e.clipboardData?.setData('text/plain', text);

          e.preventDefault();
        } catch (error) {}
      });
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <GlobalTwStyles />
      <AnimatePresence key={id || 'layout'}>
        <Styled.Layout>
          <Headroom style={{ zIndex: 30 }} calcHeightOnResize>
            <Header siteTitle={data.site.siteMetadata.title} />
          </Headroom>
          <NotificationWrapper />
          <motion.div
            className="flex flex-col flex-1 h-full text-black"
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2 }}
          >
            {children}
            {/*<Newsletter />*/}
          </motion.div>
          <Footer />
        </Styled.Layout>
      </AnimatePresence>
      <CookieConsent
        containerClasses={styles.policyLink}
        location="bottom"
        buttonText={t('cookieAccept')}
        declineButtonText={t('cookieDecline')}
        cookieName="gatsby-gdpr-google-tagmanager"
      >
        <div dangerouslySetInnerHTML={{ __html: t('cookieConsent').replace("'cookieHref'", cookieHref) }} />
      </CookieConsent>
    </>
  );
};

export default Layout;
