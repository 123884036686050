import React from 'react';

import MainNav from './MainNav';
import Logo from './Logo';

import * as Styled from './styles';
import { useI18next } from 'gatsby-plugin-react-i18next';

interface Props {
  siteTitle: string;
}

const Header: React.FC<Props> = ({ siteTitle }) => {
  const { t } = useI18next();

  return (
    <Styled.Header>
      <Styled.Wrapper>
        <Logo/>
        <Styled.Text>{siteTitle || t('siteTitle')}</Styled.Text>
        <MainNav/>
      </Styled.Wrapper>
    </Styled.Header>
  );
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
