import React from 'react';

import Container from 'components/ui/Container';

import * as Styled from './styles';
import { useI18next } from 'gatsby-plugin-react-i18next';
import CopyText from '../CopyText';
import Icon from '../Icon';
import Link from 'gatsby-link';
import HorizontalTitleSeparator from '../HorizontalTitleSeparator/HorizontalTitleSeparator';
import * as styles from '../LawPage/styles.module.scss';

const Footer: React.FC = () => {
  const { t } = useI18next();

  return (
    <Styled.Footer>
      <Container>
        <Styled.LinksTitleBig>{t('footerTitle')}</Styled.LinksTitleBig>
        <Styled.LinksContainer>
          <Styled.BigLinks>
            {/*<Styled.LinksTitleTwo>{t('footerColOneTitleTwo')}</Styled.LinksTitleTwo>*/}
            <Styled.LinksSubTitle>{t('rapidContact')}</Styled.LinksSubTitle>
            <CopyText variant="copy-2" additionalClass="mb-0 mt-8 flex items-center">
              {/*<span*/}
              {/*  style={{*/}
              {/*    fontSize: '32px',*/}
              {/*    lineHeight: '16px',*/}
              {/*    marginBottom: '6px',*/}
              {/*    marginLeft: '4px',*/}
              {/*    marginRight: '4px',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  &#9990;*/}
              {/*</span>*/}
              <Icon name="Phone" />
              <a href="tel: +40 746 038 556" style={{ fontSize: '16px' }}>
                Tel: 0746 038 556
              </a>
            </CopyText>
            <CopyText variant="copy-2" additionalClass="mb-4">
              <a
                href="mailto: cabinet.av.szilagyi@gmail.com"
                className="flex items-center justify-center md:justify-start text-center md:text-left"
              >
                <Icon name="Mail" additionalClass="mr-12 mt-4" size="l" />
                E-mail:
              </a>
              <a href="mailto: cabinet.av.szilagyi@gmail.com">cabinet.av.szilagyi@gmail.com</a>
            </CopyText>
            <CopyText variant="copy-2" additionalClass="mb-0 block text-center md:text-left">
              <span className="flex items-center">
                <Icon name="OfficeLocation" additionalClass="mt-4" size="l" /> {`${t('address')}: Rovine Nr. 2`}
              </span>
            </CopyText>
          </Styled.BigLinks>
          <Styled.Links>
            <Styled.LinksTitle>{t('siteMap')}</Styled.LinksTitle>
            <Link to="/">{t('home')}</Link>
            <Link to="/news">{t('news')}</Link>
            <Link to="/cookie-policy" style={{ maxWidth: '200px' }}>
              {t('cookieFooter')}
            </Link>
            <Link to="/useful-links" style={{ maxWidth: '200px' }}>
              {t('usefulLinks')}
            </Link>
            <Link to="/contact">{t('contact')}</Link>
          </Styled.Links>
          <Styled.LastLinks>
            <div className="flex flex-col flex-1 md:pl-60">
              <Styled.LinksTitle>{t('info')}</Styled.LinksTitle>
              <Link to="/about" className="mb-4 text-center md:text-left">
                {t('aboutUs')}
              </Link>

              <Link to="/services" className="mb-4 text-center md:text-left">
                {t('services')}
              </Link>

              <Link to="/fields" className="mb-4 text-center md:text-left">
                {t('fields')}
              </Link>
              <Styled.LinksTitle className="mt-4 text-center md:text-left">{t('socialMedia')}</Styled.LinksTitle>
              <CopyText variant="copy-4" additionalClass="text-center md:text-left">
                <a href="https://www.linkedin.com/in/szilagyikinga/">
                  <Icon name="LinkedIn" />
                </a>
                <a href="https://www.facebook.com/avocat.Szilagyi.Kinga.ugyved">
                  <Icon name="Facebook" />
                </a>
                <a href="https://www.instagram.com/kinga.legal">
                  <svg
                    style={{ display: 'inline-block', marginLeft: '2px', marginTop: '4px' }}
                    height="22px"
                    width="22px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 551.034 551.034"
                    // xml:space="preserve"
                  >
                    <g id="XMLID_13_">
                      <linearGradient
                        id="XMLID_2_"
                        gradientUnits="userSpaceOnUse"
                        x1="275.517"
                        y1="4.5714"
                        x2="275.517"
                        y2="549.7202"
                        gradientTransform="matrix(1 0 0 -1 0 554)"
                      >
                        <stop offset="0" style={{ stopColor: '#E09B3D' }} />
                        <stop offset="0.3" style={{ stopColor: '#C74C4D' }} />
                        <stop offset="0.6" style={{ stopColor: '#C21975' }} />
                        <stop offset="1" style={{ stopColor: '#7024C4' }} />
                      </linearGradient>
                      <path
                        id="XMLID_17_"
                        style={{ fill: 'url(#XMLID_2_)' }}
                        d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722
		c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156
		C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156
		c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722
		c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
                      />

                      <linearGradient
                        id="XMLID_3_"
                        gradientUnits="userSpaceOnUse"
                        x1="275.517"
                        y1="4.5714"
                        x2="275.517"
                        y2="549.7202"
                        gradientTransform="matrix(1 0 0 -1 0 554)"
                      >
                        <stop offset="0" style={{ stopColor: '#E09B3D' }} />
                        <stop offset="0.3" style={{ stopColor: '#C74C4D' }} />
                        <stop offset="0.6" style={{ stopColor: '#C21975' }} />
                        <stop offset="1" style={{ stopColor: '#7024C4' }} />
                      </linearGradient>
                      <path
                        id="XMLID_81_"
                        style={{ fill: 'url(#XMLID_3_)' }}
                        d="M275.517,133C196.933,133,133,196.933,133,275.516
		s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6
		c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083
		C362.6,323.611,323.611,362.6,275.517,362.6z"
                      />

                      <linearGradient
                        id="XMLID_4_"
                        gradientUnits="userSpaceOnUse"
                        x1="418.306"
                        y1="4.5714"
                        x2="418.306"
                        y2="549.7202"
                        gradientTransform="matrix(1 0 0 -1 0 554)"
                      >
                        <stop offset="0" style={{ stopColor: '#E09B3D' }} />
                        <stop offset="0.3" style={{ stopColor: '#C74C4D' }} />
                        <stop offset="0.6" style={{ stopColor: '#C21975' }} />
                        <stop offset="1" style={{ stopColor: '#7024C4' }} />
                      </linearGradient>
                      <circle id="XMLID_83_" style={{ fill: 'url(#XMLID_4_)' }} cx="418.306" cy="134.072" r="34.149" />
                    </g>
                  </svg>
                </a>
              </CopyText>
            </div>
          </Styled.LastLinks>
        </Styled.LinksContainer>
        <HorizontalTitleSeparator title={t('footerCopyright')} copyright />
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
