import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

type Meta =
  | {
      name: string;
      content: any;
    }
  | {
      property: string;
      content: any;
    };

interface Props {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title: string;
}

const SEO: React.FC<Props> = ({ lang, meta, title }) => {
  const { t } = useI18next();

  const url = typeof window !== 'undefined' ? window.location.href : '';

  const metaDescription = t(`siteDescription${title}`);
  let pageTitle = t(`siteTitle${title}`);

  if (url.includes('news')) {
    pageTitle = title;
  }

  const metaAuthor = t('siteAuthor');
  const baseSiteTitle = t('siteTitle');
  const metaTitle = `${pageTitle} | ${baseSiteTitle}`;



  useEffect(() => {
    // @ts-ignore
    window.prerenderReady = true;
  }, []);
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: metaAuthor
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta!)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [] as Meta[],
  description: ``
};

export default SEO;
