import React from 'react';
import c from 'classnames';
import * as styles from './style.module.scss';

export type CopyTextType = 'copy-1' | 'copy-2' | 'copy-3' | 'copy-4' | 'copy-5' | 'copy-6' | 'copy-7' | 'copy-8';

type CopyTextProps = {
  centered?: boolean;
  ellipsis?: boolean;
  large?: boolean;
  firstLetter?: boolean;
  onClick?: (...args: any) => any;
  color?: string;
  variant?: CopyTextType;
  additionalClass?: string;
  spaced?: boolean;
  style?: any;
};

const CopyText: React.FC<CopyTextProps> = ({
  large,
  firstLetter,
  children,
  spaced,
  style = {},
  centered = false,
  ellipsis = false,
  color = 'inherit',
  variant = 'copy-2',
  onClick = () => {},
  additionalClass = undefined,
}) => (
  <p
    aria-hidden="true"
    onClick={onClick}
    style={{ ...style }}
    className={c(
      variant,
      { 'text-lg': large },
      { [styles.spaced]: spaced },
      { [styles.firstLetter]: firstLetter },
      { 'text-center block': centered },
      { 'text-black': color === 'black' },
      { 'text-dark': color === 'dark' },
      { 'text-blue-n': color === 'blue' },
      { 'text-blue-ocean': color === 'ocean' },
      { 'text-white': color === 'white' },
      { [styles.ellipsis]: ellipsis },
      additionalClass
    )}
  >
    {children}
  </p>
);

export default CopyText;
