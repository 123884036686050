import styled from 'styled-components';
import tw from 'twin.macro';

export interface StyledProps {
  section?: boolean;
}

export const Container = styled.div<StyledProps>`
  ${tw`flex flex-wrap max-w-screen-lg 2xl:max-w-screen-xl w-full mx-auto p-12 `};
  ${({ section }) => section && tw`py-16 sm:py-24`};
`;
