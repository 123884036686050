import React, { useState } from 'react';
import LanguageDropdown from '../LanguageDropdown';

import * as Styled from './styles';
import { useI18next } from 'gatsby-plugin-react-i18next';

interface MainNavItem {
  type?: string;
  title: string;
  slug: string;
}

const mainNavItems: MainNavItem[] = [
  {
    title: 'home',
    slug: '/',
  },
  {
    title: 'about',
    slug: '/about/',
  },
  {
    title: 'services',
    slug: '/services/',
  },
  {
    title: 'fields',
    slug: '/fields/',
  },
  // {
  //   title: 'faq',
  //   slug: '/faq/',
  // },
  {
    title: 'news',
    slug: '/news/',
  },
  {
    title: 'contact',
    slug: '/contact/',
  },
  // {
  //   type: 'language',
  //   title: '',
  //   slug: '',
  // },
];

const MainNav: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useI18next();

  return (
    <>
      <Styled.MainNav open={open}>
        {mainNavItems.map((item, index) =>
          item.type === 'language' ? (
            <LanguageDropdown key={`nav-item-${index}`} />
          ) : (
            <Styled.MainNavItem
              key={`nav-item-${index}`}
              to={item.slug}
              activeClassName="active"
              whileTap={{ scale: 0.9 }}
            >
              {t(item.title)}
            </Styled.MainNavItem>
          )
        )}
      </Styled.MainNav>
      {/*<LanguageDropdown />*/}
      <Styled.ToogleMainNav open={open} onClick={() => setOpen(!open)}>
        <span />
        <span />
        <span />
      </Styled.ToogleMainNav>
    </>
  );
};

export default MainNav;
