import React from 'react';
import { useI18next, Link as I18nLink } from 'gatsby-plugin-react-i18next';
import { LanguageSelect } from './styles';

function getLabel(originalPath: string, language: string, t: (v: string) => string, forValue?: boolean) {
  const label = t(language);
  let icon;

  switch (language) {
    case 'ro':
      icon = `🇷🇴`;
      break;
    case 'en':
      icon = `🇬🇧`;
      break;
    case 'hu':
      icon = `🇭🇺`;
      break;
    default:
      return '';
  }

  icon = <span className={forValue ? 'mt-3 mr-5' : 'ml-auto'}>{icon}</span>;

  const LinkCmp = forValue ? 'span' : I18nLink;

  return (
    <LinkCmp
      to={originalPath}
      language={language}
      className="flex items-center justify-space-between px-5 truncate no-underline"
      style={{ color: '#3c366b'}}
    >
      {forValue ? null : <span>{`${label}  `}</span>}
      {/*{icon}*/}
      {forValue ? <span className="ml-auto">{`${label}  `}</span> : null}
    </LinkCmp>
  );
}

const LanguageDropdown: React.FC = () => {
  const { languages, originalPath, t, language, defaultLanguage } = useI18next();

  const options = languages.map(language => ({
    value: language,
    label: getLabel(originalPath, language, t),
  }));

  return (
    <LanguageSelect
      isSearchable={false}
      options={options}
      value={{
        label: getLabel(originalPath, language || defaultLanguage, t, true),
        value: language || defaultLanguage,
      }}
    />
  );
  // return (
  //   <ul className="languages">
  //     {languages &&
  //       languages.length > 0 &&
  //       languages.map(lng => (
  //         <li key={lng}>
  //           <I18nLink to={originalPath} language={lng}>
  //             {lng}
  //           </I18nLink>
  //         </li>
  //       ))}
  //   </ul>
  // );
};

export default LanguageDropdown;
