import React from 'react';
import c from 'classnames';
import * as styles from './style.module.scss';
import CopyText, { CopyTextType } from '../CopyText';

type Props = {
  title: string;
  variant?: CopyTextType;
  additionalClass?: string;
  copyright?: boolean;
};

const HorizontalTitleSeparator: React.FC<Props> = ({ variant = 'copy-1', additionalClass, title, copyright }) => (
  <div className={c('flex items-center w-full', additionalClass)}>
    <div className={styles.horizontalTitleSeparatorLeft} />
    <CopyText
      variant={variant}
      color={copyright ? '' : 'dark'}
      additionalClass={c(styles.title, copyright && styles.copyright)}
    >
      {title}
    </CopyText>
    <div className={styles.horizontalTitleSeparator} />
  </div>
);

export default HorizontalTitleSeparator;
