import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby';

export const Logo = styled(Link)`
  ${tw`flex flex-1 items-center flex-col text-indigo-900 hover:text-indigo-900`};
  font-weight: 500;
  max-width: 100px;
  min-width: 100px;
  
  //@media screen and (min-width: 800px) {
  //  max-width: 130px;
  //  min-width: 130px;
  //}

  img {
    max-width: 100px;
    min-width: 100px;
  }

  @media screen and (min-width: 1024px) {
    img {
      min-width: 130px;
      max-width: 130px;
    }
  }
`;

export const Text = styled.h1`
  ${tw`text-sm md:text-base lg:text-lg hidden sm:block md:hidden text-center`};
  max-width: 160px;
  font-weight: 500;
`;

export const Image = styled.figure`
  ${tw`w-48 h-48 mr-8 border border-teal-400 rounded-full`};

  img {
    ${tw`border-4 border-white rounded-full`};
  }
`;
