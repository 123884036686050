import React from 'react';
import c from 'classnames';
import Icon from '../Icon';
import { Badge, BadgeProps } from '../Badge';

import * as styles from './Tag.module.scss';

export type TagProps = Omit<BadgeProps, 'onClick' | 'disabled'> & {
  ContentComponent?: keyof JSX.IntrinsicElements;
  onRemove?: () => void;
};

const Tag: React.FC<TagProps> = ({ ContentComponent = 'span', additionalClass, onRemove, children, ...otherProps }) => {
  const isRemovable = !!onRemove;

  return (
    <Badge {...otherProps} additionalClass={c(styles.tag, isRemovable && styles.removable, additionalClass)}>
      <ContentComponent>{children}</ContentComponent>
      {isRemovable && <Icon name="Close" additionalClass={styles.remove} onClick={onRemove} id="tag-close-icon" />}
    </Badge>
  );
};

export default Tag;
