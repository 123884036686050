import React from 'react';
import c from 'classnames';
import Icon, { IconNames } from '../Icon';
import { NotificationType } from '../../infrastructure/notifications';
import { Tag } from '../Tag';
import CopyText from '../CopyText';
import * as styles from './style.module.scss';

export type Props = { onClose?: () => void; additionalClass?: string } & (NotificationType | undefined);

const Notification: React.FC<Props> = ({ additionalClass, type, title, description, closeable, onClose }) => {
  let icon: IconNames = 'InfoMessage';

  if (type === 'success') {
    icon = 'SuccessMessage';
  } else if (type === 'error') {
    icon = 'ErrorMessage';
  } else if (type === 'warning') {
    icon = 'WarningMessage';
  }

  return (
    <Tag
      variant={type}
      size="auto"
      onRemove={closeable && onClose ? onClose : undefined}
      ContentComponent="div"
      additionalClass={c(styles.notification, additionalClass)}
    >
      <div className={styles.container}>
        <Icon name={icon} additionalClass={styles.notificationIcon} />
        <div className={styles.textContainer}>
          {title && (
            <CopyText variant="copy-3" color="inherit">
              {title}
            </CopyText>
          )}
          {description && (
            <CopyText
              variant="copy-6"
              color="inherit"
              additionalClass={c(styles.description, !title && styles.noTitle)}
            >
              {description}
            </CopyText>
          )}
        </div>
      </div>
    </Tag>
  );
};

export default Notification;
