import styled from 'styled-components';
import tw from 'twin.macro';

export const Footer = styled.footer`
  ${tw`border-t border-gray-200 bg-gray-100 py-4 mt-auto`};
`;

export const LinksContainer = styled.div`
  ${tw`grid grid-cols-10 items-start justify-start text-left w-full mb-20 mt-15`};
`;

export const LinksTitle = styled.div`
  ${tw`text-lg mb-5 font-bold text-center md:text-left`};
`;
export const LinksTitleBig = styled.div`
  ${tw`text-xl mb-5 font-bold text-center md:text-left mx-auto md:mx-0`};
`;
export const LinksTitleOne = styled.div`
  ${tw`text-lg mb-0 font-bold text-center md:text-left`};
`;
export const LinksTitleTwo = styled.div`
  ${tw`text-lg mb-5 text-center md:text-left`};
`;

export const LinksSubTitle = styled.div`
  ${tw`text-lg mb-5 font-bold text-center md:text-left`};
`;

export const Links = styled.div`
  ${tw`flex flex-col items-center md:items-start md:justify-start text-center md:text-left justify-center w-full col-span-10 md:col-span-3 mb-15`};

  a {
    ${tw`text-indigo-900 hover:text-indigo-600 mx-2 mb-4`};
  }
`;

export const BigLinks = styled.div`
  ${tw`flex flex-col items-center md:items-start md:justify-start justify-center w-full col-span-10 md:col-span-4 mb-15`};

  a {
    ${tw`text-indigo-900 hover:text-indigo-600 mx-2 mb-4`};
  }
`;

export const LastLinks = styled.div`
  ${tw`flex flex-col items-center md:items-start md:justify-start justify-center w-full col-span-10 md:col-span-3 mb-15`};

  a {
    ${tw`text-indigo-900 hover:text-indigo-600 mx-2`};
  }
`;

export const Link = styled.a`
  ${tw`text-indigo-900 hover:text-indigo-600 mx-2`};
`;
