import React from 'react';
import c from 'classnames';

import * as styles from './Badge.module.scss';

export type BadgeVariant = 'default' | 'info' | 'success' | 'error' | 'warning' | 'light';

export type BadgeProps = {
  disabled?: boolean;
  outlined?: boolean;
  italic?: boolean;
  size?: 'm' | 'l' | 'auto';
  variant?: BadgeVariant;
  additionalClass?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Badge: React.FC<BadgeProps> = ({
  disabled,
  outlined,
  size = 'm',
  variant = 'default',
  onClick,
  italic,
  additionalClass,
  children,
}) => {
  const isClickable = !!onClick;
  const Component = isClickable ? 'button' : 'div';

  return (
    <Component
      disabled={disabled}
      className={c(
        styles.badge,
        styles[variant],
        {
          [styles.large]: size === 'l',
          [styles.italic]: italic,
          [styles.medium]: size === 'm',
          [styles.auto]: size === 'auto',
          [styles.outlined]: outlined,
          [styles.disabled]: disabled,
          [styles.clickable]: isClickable,
        },
        additionalClass
      )}
    >
      {children}
    </Component>
  );
};

export default Badge;
