import { useCallback } from 'react';
import useIsMounted from './useIsMounted';

const isBrowser = typeof window !== 'undefined';

const useSecureTimeout = () => {
  const isMounted = useIsMounted();

  return useCallback(
    (callback: () => void, timeout: number = 0): number | undefined => {
      if (!isBrowser) return undefined;

      return window.setTimeout((): void => {
        if (isMounted) callback();
      }, timeout);
    },
    [isMounted]
  );
};

export default useSecureTimeout;
