import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Notification from '../Notification';
import { selectNotification, dismissNotification } from '../../infrastructure/notifications';
import * as styles from './style.module.scss';
import useSecureTimeout from '../../utils/useSecureTimeout';

interface NotificationProps {
  additionalClass?: string;
}

const NotificationWrapper: React.FC<NotificationProps> = ({ additionalClass = undefined }) => {
  const { currentNotification: CN } = useSelector(selectNotification);
  const dispatch = useDispatch();
  const [shownNotification, setShownNotification] = useState(CN);

  const timeoutRef = useRef<number>();
  const setSecureTimeout = useSecureTimeout();

  const dismiss = useCallback(() => {
    dispatch(dismissNotification());
  }, [dispatch]);

  useEffect(() => {
    if (CN) {
      setShownNotification(CN);

      clearTimeout(timeoutRef.current);

      const notificationSize = (CN.title?.length || 0) + (CN.description?.length || 0);

      if (CN.type !== 'error') {
        const delay = Math.max(notificationSize * 50, 2000);

        timeoutRef.current = setSecureTimeout(() => dismiss(), delay);
      }
    }
  }, [dismiss, CN, setSecureTimeout]);

  return (
    <div className={cn(styles.wrapper, additionalClass)}>
      <div
        className={cn(styles.container, {
          [styles.hidden]: !CN,
        })}
        role="alertdialog"
        aria-labelledby="notification-text"
      >
        {shownNotification && <Notification {...shownNotification} onClose={dismiss} />}
      </div>
    </div>
  );
};

export default NotificationWrapper;
