// extracted by mini-css-extract-plugin
export var auto = "Badge-module--auto--t-Su-";
export var badge = "Badge-module--badge--3yFAN";
export var clickable = "Badge-module--clickable--aTI8S";
export var disabled = "Badge-module--disabled--2stWr";
export var error = "Badge-module--error--46NTH";
export var info = "Badge-module--info--ool2Q";
export var italic = "Badge-module--italic--xUabi";
export var large = "Badge-module--large--0FVtX";
export var light = "Badge-module--light--BgFMe";
export var medium = "Badge-module--medium--bM+t4";
export var outlined = "Badge-module--outlined--+89Vm";
export var success = "Badge-module--success--UbX5x";
export var warning = "Badge-module--warning--psY2A";